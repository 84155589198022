import Agriculture from "../views/Agriculture";
import Business from "../views/BusinessHub";
import Religious from "../views/ReligiousPlace";
import Iframe from "../views/Iframe"

const indexRoutes = [
  // { path: "/businesshub", name: "Business", component: Agriculture },
  // { path: "/religiousplace", name: "Religious", component: Agriculture },
  { path: "/iframe", name: "Agriculture", component: Iframe },
  { path: "/", name: "HomePage", component: Agriculture },
];

export default indexRoutes;
