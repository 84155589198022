import React, { useRef, useState, useEffect } from "react";
import "./Map.css";
import * as olSource from "ol/source";
import OLTileLayer from "ol/layer/Tile";
import * as ol from "ol";
import "ol/ol.css";

const Map = ({ zoom, center, ext, onMap }) => {
  const mapRef = useRef();
  const [map, setMap] = useState(null);

  // on component mount
  useEffect(() => {
    let options = {
      view: new ol.View({
        zoom,
        extent: ext,
        center: center,
        projection: "EPSG:4326",
      }),
      layers: [],
      // controls: [],
      overlays: [],
    };

    let mapObject = new ol.Map(options);
    mapObject.setTarget(mapRef.current);

    setMap(mapObject);

    return () => {
      mapObject.setTarget(undefined);
    };
  }, []);

  useEffect(() => {
    if (!map) {
      return;
    }

    let tileLayer = new OLTileLayer({
      className: "base-layer",
      source: new olSource.OSM(),
      zIndex: 0,
    });

    map.addLayer(tileLayer);
    tileLayer.setZIndex(0);
    return () => {
      if (map) {
        map.removeLayer(tileLayer);
      }
    };
  }, [map]);

  //map instance passing to the parent
  useEffect(() => {
    if (!map) return;

    if (onMap) {
      onMap(map);
    }
  }, [map]);

  return <div ref={mapRef} className="ol-map"></div>;
};

export default Map;
