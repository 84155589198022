import { Circle } from "ol/style";
import { Stroke, Style, Fill, Text } from "ol/style";

export function PointStyleFunction(feature, resolution) {
  const style_json = feature.getProperties().style;

  if (!style_json.label_column) {
    return new Style({
      image: new Circle({
        radius: `${style_json.radius_for_point}`,
        fill: new Fill({
          color: `${style_json.fill_color}`,
        }),
        stroke: new Stroke({
          color: `${style_json.stroke_color}`,
          width: `${style_json.stroke_width}`,
        }),
      }),
    });
  } else if (style_json) {
    return new Style({
      image: new Circle({
        radius: `${style_json.radius_for_point}`,
        fill: new Fill({
          color: `${style_json.fill_color}`,
        }),
        stroke: new Stroke({
          color: `${style_json.stroke_color}`,
          width: `${style_json.stroke_width}`,
        }),
      }),
      text: new Text({
        font: `${style_json.label_font_size}px ${style_json.label_font_class}`,
        text: String(feature.getProperties()[`${style_json.label_column}`]),
        placement: "polygon",
        fill: new Fill({
          color: `${style_json.label_fill_color}`,
        }),
        stroke: new Stroke({
          color: `${style_json.label_stroke_color}`,
          width: `${style_json.label_stroke_width}`,
        }),
      }),
    });
  }
  return new Style({
    image: new Circle({
      radius: 7,
      fill: new Fill({
        color: [245, 49, 5, 1],
      }),
      stroke: new Stroke({
        color: "blue",
        width: 1,
      }),
    }),
  });
}
