import React, { useEffect } from "react";
import { useState } from "react";
import "./index.css";
import Map from "../../common/Map/Map";
import { fromLonLat, transform } from "ol/proj";
import ControlledAccordions from "../../common/Accordion";
import Legend from "../../common/Legend";
import SelectComponent from "../../common/Select";

export default function Agriculture() {
  const [center, setCenter] = useState([26.5455, 87.8942]);
  const [ext, setExt] = useState([81.001, 25.72, 89.8, 28.2]);
  const [zoom, setZoom] = useState(1);
  const [map, setMap] = useState();
  const [legend, setLegend] = useState([]);

  const onMap = (evMap) => {
    if (evMap) {
      setMap(evMap);
    }
  };

  const removeLegend = (layer_id) => {
    setLegend((prevState) =>
      prevState.filter((prevItem) => prevItem.layer_id !== layer_id)
    );
  };

  const onIsLegend = (evLegend) => {
    setLegend((oldArray) => [...oldArray, evLegend]);
  };
  return (
    <div className="overall-container">
      <div className="header-container">
        <header className="bg-light">
          <div className="container">
            <div className="row main-header py-3 align-items-center">
              <div className="col-md-2 logo">
                <img
                  src="https://koshi.gov.np/images/logo/abbb02cf85ce35f9154eadd71a48f685.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-md-8 head-title text-center">
                <p>प्रदेश सरकार</p>
                <p>कोशी प्रदेश, विराटनगर, नेपाल</p>
                <h2>Geographic Information System Portal</h2>
              </div>
              <div className="col-md-2">
                <div className="main-header-flag float-right">
                  <img
                    src="https://koshi.gov.np/web/img/flag.gif"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>

      <div className="container-map">
        <aside>
          <ControlledAccordions
            map={map}
            onIsLegend={onIsLegend}
            removeLegend={removeLegend}
          />
        </aside>
        <div className="map">
          <Map
            center={fromLonLat(center)}
            zoom={zoom}
            ext={ext}
            onMap={onMap}
          ></Map>
          {legend.length !== 0 ? <Legend layers_id={legend} /> : null}
          {map ? <SelectComponent map={map} /> : null}
        </div>
      </div>
      <footer className="footer">
        <p>कोशी प्रदेश, विराटनगर, नेपाल</p>
        <div className="col-md-2"></div>
      </footer>
    </div>
  );
}
