import React, { useState } from "react";
import "./index.css";
import axios from "axios";
import { BASE_URL_BACKEND } from "../../Api/root";

export function hexToRgba(hex, opacity = 100) {
  if (!hex) return "";
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);
  const a = opacity * 0.01;
  return `rgba(${r}, ${g}, ${b}, ${a})`;
}

const Legend = (props) => {
  return (
    <div className="legend">
      {props.layers_id !== 0
        ? props.layers_id.map((item, index) => {
            // eslint-disable-next-line jsx-a11y/alt-text
            return item.layer_type !== "RASTER" ? (
              <div key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "center",
                }}
               >
                <div
                  style={{
                    height: "12px",
                    width: "12px",
                    backgroundColor: hexToRgba(item.style.fill_color),
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
                <h6  style={{ height: "15px" }}>{item.style.layer_name}</h6>
              </div>
            ) : null;
          })
        : null}
    </div>
  );
};
export default Legend;
