import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { Vector as VectorLayer } from "ol/layer";
import { transformExtent } from "ol/proj";

export const addVectorLayertoMap = (geojson, value, mapview, styleFunction) => {
  const vectorSource = new VectorSource({
    features: new GeoJSON().readFeatures(geojson),
  });
  const extent = transformExtent(
    vectorSource.getExtent(),
    "EPSG:4326",
    "EPSG:3857"
  );
  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: styleFunction,
  });
  vectorLayer.set("name", `${value}`);
  mapview.getView().fit(extent, {
    padding: [50, 50, 50, 50],
    duration: 500,
    maxZoom: 8,
    constrainResolution: true,
  });
  mapview.addLayer(vectorLayer);
};
