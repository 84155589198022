import axios from "axios";
import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { Vector as VectorLayer } from "ol/layer";
import { Style, Fill } from "ol/style";
import { BASE_URL } from "../../Api/root";
import { getVectorContext } from "ol/render";

export const addRastertoMap = (
  category_name,
  layer_name_geoserver,
  value,
  mapview,
  layer_name,
  geojson_province
) => {
  const WMSlayer = new TileLayer({
    source: new TileWMS({
      url: `${BASE_URL}/geoserver/wms`,
      params: {
        FORMAT: "image/png",
        tiled: true,
        VERSION: "1.1.0",
        LAYERS: `${category_name}:${layer_name_geoserver}`,
      },
    }),
  });
  WMSlayer.set("name", `${value}`);

  // clipping the raster

  const clipLayer = new VectorLayer({
    style: null,
    source: new VectorSource({
      features: new GeoJSON().readFeatures(geojson_province),
    }),
  });

  //Giving the clipped layer an extent is necessary to avoid rendering when the feature is outside the viewport
  clipLayer.getSource().on("addfeature", function () {
    WMSlayer.setExtent(clipLayer.getSource().getExtent());
  });

  const style = new Style({
    fill: new Fill({
      color: "black",
    }),
  });

  WMSlayer.on("postrender", function (e) {
    const vectorContext = getVectorContext(e);
    e.context.globalCompositeOperation = "destination-in";
    clipLayer.getSource().forEachFeature(function (feature) {
      vectorContext.drawFeature(feature, style);
    });
    e.context.globalCompositeOperation = "source-over";
  });

  // end of clipping
  axios({
    method: "get",
    url: `${BASE_URL}/geoserver/rest/workspaces/${category_name}/coveragestores/${layer_name}/coverages/${layer_name_geoserver}`,
    auth: {
      username: "admin",
      password: "geoserver",
    },
  }).then((response) => {
    const extent_obj = response.data.coverage.nativeBoundingBox;
    const ext = [
      extent_obj.minx,
      extent_obj.miny,
      extent_obj.maxx,
      extent_obj.maxy,
    ];
    mapview.getView().fit(ext, {
      padding: [50, 50, 50, 50],
      duration: 500,
      maxZoom: 8,
      constrainResolution: true,
    });
  });

  mapview.addLayer(WMSlayer);
};
