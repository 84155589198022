import React,{useState,useEffect} from 'react';
import Map from "../../common/Map/Map";
import { fromLonLat} from "ol/proj";
import SelectComponent from "../../common/Select/index_iframe";
import { addVectorLayertoMap } from "../../common/Checkbox/addVectorLayertoMap";
import { adminPolygonStyleFunctionmuni } from './adminPolygonStylemuni';
import axios from 'axios';
import { BASE_URL_BACKEND } from '../../Api/root';

export default function Iframe() {
    const [center, setCenter] = useState([26.5455, 87.8942]);
    const [ext, setExt] = useState([81.001, 25.72, 89.8, 28.2]);
    const [zoom, setZoom] = useState(1);
    const [map, setMap] = useState();

    const onMap = (evMap) => {
        if (evMap) {
          setMap(evMap);
        }
      };
    useEffect(()=>{
        if (map){
            axios({
                method: "get",
                url: `${BASE_URL_BACKEND}/polygon-json/${1}`,
              }).then((response) => {
                const geojson = response.data;
                addVectorLayertoMap(
                  geojson,
                  "province-iframe",
                  map,
                  adminPolygonStyleFunctionmuni
                );
              });
        }
     
    },[map])
 
    return(<div className="map">
    <Map
      center={fromLonLat(center)}
      zoom={zoom}
      ext={ext}
      onMap={onMap}
    ></Map>

    {map ? <SelectComponent map={map} /> : null}
  </div>)
    
}
