import { useEffect } from "react";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AgroLayers from "../Checkbox/index";
import axios from "axios";
// import { BASE_URL } from "../../Api/root";
import { BASE_URL_BACKEND } from "../../Api/root";

export default function ControlledAccordions(props) {
  const [titles, setTitles] = React.useState();

  const handleChange = (panel) => () => {
    const new_titles = titles.map((item) => {
      if (String(item.id) === panel) {
        if (item.expanded === false) {
          item.expanded = true;
        } else {
          item.expanded = false;
        }
      }
      return item;
    });
    setTitles(new_titles);
  };

  useEffect(() => {
    axios({
      method: "get",
      url: `${BASE_URL_BACKEND}/category/`,
    }).then((response) => {
      const categorynames = response.data;
      const categorynames_with_expanded = categorynames.map(
        (category, index) => {
          category.expanded = true;
          return category;
        }
      );
      return setTitles(categorynames_with_expanded);
    });
  }, []);

  return (
    <>
      {titles
        ? titles.map((item) => {
            return (
              <Accordion
                sx={{
                  backgroundColor: "white",
                }}
                key={item.id}
                expanded={item.expanded}
                onChange={handleChange(String(item.id))}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography
                    sx={{ width: "100%", flexShrink: 0, color: "black" }}
                  >
                    {item.name}
                  </Typography>
                </AccordionSummary>
                <AgroLayers
                  category_name={item.name}
                  category_id={item.id}
                  map={props.map}
                  onIsLegend={props.onIsLegend}
                  removeLegend={props.removeLegend}
                />
              </Accordion>
            );
          })
        : null}
    </>
  );
}
