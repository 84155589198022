import { useEffect, useState } from "react";
import { Stroke, Style, Fill, Circle } from "ol/style";
import Select from "ol/interaction/Select";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import axios from "axios";
import { BASE_URL_BACKEND } from "../../Api/root";
import { BASE_BACKEND_MEDIA } from "../../Api/root";
import { addVectorLayertoMap } from "../Checkbox/addVectorLayertoMap";
import { adminPolygonStyleFunctiondistrict } from "./adminPolygonStyledistrict";
import { adminPolygonStyleFunctionmuni } from "./adminPolygonStylemuni";
import { adminPolygonStyleFunctionward } from "./adminPolygonStyleward";

const select = new Select({
  style: function selectStylePoint(feature) {
    const geom_type = feature.getGeometry().getType();
    if (geom_type === "MultiPoint") {
      return new Style({
        image: new Circle({
          radius: 7,
          fill: new Fill({
            color: "yellow",
          }),
          stroke: new Stroke({
            color: "green",
            width: 2,
          }),
        }),
      });
    } else if (geom_type === "MultiLineString") {
      return new Style({
        stroke: new Stroke({
          color: `yellow`,
          width: `2`,
        }),
        fill: new Fill({
          color: `green`,
        }),
      });
    }
    return new Style({
      fill: new Fill({
        color: "yellow",
      }),
      stroke: new Stroke({
        color: "green",
        width: 2,
      }),
    });
  },
});

export default function SelectComponent(props) {
  const [image, setImages] = useState();
  const [properties, setProperties] = useState();
  const [keys, setKeys] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    props.map.addInteraction(select);
    select.on("select", (event) => {
      if (event.selected[0] === undefined) {
        setImages();
        setKeys();
        setDescription();
      } else {
        console.log("I am here selected ");
        // props.map.addInteraction(select);
        const properties_all = event.selected[0].getProperties();
        // Now check the layer name of the selected layer
        const layer_name = properties_all.layer_name;
        if (layer_name === "Province") {
          axios({
            method: "get",
            url: `${BASE_URL_BACKEND}/polygon-json/${3}/`,
          }).then((response) => {
            const geojson = response.data;
            addVectorLayertoMap(
              geojson,
              event.target.value,
              props.map,
              adminPolygonStyleFunctiondistrict
            );
          });
        } else if (layer_name === "District") {
          const district_id = properties_all.districtiD;
          axios({
            method: "get",
            url: `${BASE_URL_BACKEND}/polygon-json/${4}/?district_id=${String(
              district_id
            )}`,
          }).then((response) => {
            const geojson = response.data;
            addVectorLayertoMap(
              geojson,
              event.target.value,
              props.map,
              adminPolygonStyleFunctionmuni
            );
          });
        } else if (layer_name === "Municipality") {
          const municipality_id = properties_all.munid;
          axios({
            method: "get",
            url: `${BASE_URL_BACKEND}/polygon-json/${5}/?municipality_id=${String(
              municipality_id
            )}`,
          }).then((response) => {
            const geojson = response.data;
            addVectorLayertoMap(
              geojson,
              event.target.value,
              props.map,
              adminPolygonStyleFunctionward
            );
          });
        }
        delete properties_all["style"];
        delete properties_all["geometry"];
        // delete properties_all["id"];
        delete properties_all["shp_id"];
        setProperties(properties_all);
        const keys_all = Object.keys(properties_all);
        setKeys(keys_all);

        axios
          .get(`${BASE_URL_BACKEND}/layer-images/?feature=${properties_all.id}`)
          .then((response) => {
            if (response.data.length !== 0) {
              console.log(response.data[0]);
              const data = response.data[0];
              const final_photos_array = data.photos.map((photo) => {
                return `${BASE_BACKEND_MEDIA}` + photo;
              });
              console.log(final_photos_array);
              setImages(final_photos_array);
              setDescription(data.description);
            } else {
              setImages();
              setDescription();
            }
          });

        // API call to get the images and descripttion
      }
    });
  }, [props.map]);

  function clickedNext() {
    const clone_image = [...image];
    clone_image.push(clone_image.shift());
    setImages(clone_image);
  }

  return image ? (
    <>
      <div className="select">
        <div
          id="carouselExampleControls"
          className="carousel slide"
          data-mdb-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={image[0]}
                className="d-block w-100"
                alt="Wild Landscape"
              />
            </div>
          </div>
          <button
            className="carousel-control-next"
            type="button"
            data-mdb-target="#carouselExampleControls"
            data-mdb-slide="next"
            onClick={clickedNext}
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        {description ? (
          <p style={{ backgroundColor: "white", border: "1px solid black" }}>
            {description}
          </p>
        ) : null}
      </div>
      <div className="popup-container">
        <table
          className="key-value-table"
          summary="Example of a key value table."
        >
          <tbody>
            {keys.map((key, index) =>
              properties[key] !== null ? (
                <tr key={index}>
                  <th className="width-20">{key}:</th>
                  <td>{properties[key]}</td>
                </tr>
              ) : null
            )}
          </tbody>
        </table>
      </div>
    </>
  ) : keys ? (
    <div className="popup-container">
      <table
        className="key-value-table"
        summary="Example of a key value table."
      >
        <tbody>
          {keys.map((key, index) =>
            properties[key] !== null ? (
              <tr key={index}>
                <th className="width-20">{key}:</th>
                <td>{properties[key]}</td>
              </tr>
            ) : null
          )}
        </tbody>
      </table>
    </div>
  ) : null;
}
