import React, { useEffect, useState } from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { Stroke, Style, Fill } from "ol/style";
import { BASE_URL } from "../../Api/root";
import { BASE_URL_BACKEND } from "../../Api/root";
import { addVectorLayertoMap } from "./addVectorLayertoMap";
import { addRastertoMap } from "./addRastertoMap";
import { PolygonStyleFunction } from "./addPolygonStyle";
import { PointStyleFunction } from "./addPointstyle";
import { LineStyleFunction } from "./addLineStyle";

export default function Layers(props) {
  const [layers, setLayers] = useState();
  const [geojson_province, set_Geojson_province] = useState();

  const category_id = props.category_id;
  const category_name = props.category_name;

  useEffect(() => {
    axios({
      method: "get",
      url: `${BASE_URL_BACKEND}/layer/?proj=${category_id}`,
    }).then((response) => {
      const layersnames = response.data;
      setLayers(layersnames);
    });
  }, []);

  useEffect(() => {
    axios({
      method: "get",
      url: `${BASE_URL_BACKEND}/polygon-json/38/`,
    }).then((response) => {
      const geojson = response.data;
      set_Geojson_province(geojson);
    });
  }, []);

  const handleChangeCheckbox = (event) => {
    const event_value_array = event.target.value.split(",");
    const layer_id = event_value_array[0];
    const layer_type = event_value_array[1];
    const layer_name = event_value_array[2];
    const is_checked = event.target.checked;

    if (is_checked) {
      axios
        .get(`${BASE_URL_BACKEND}/layer-style/?shp=${layer_id}`)
        .then((response) => {
          props.onIsLegend({
            layer_name,
            layer_id,
            layer_type,
            style: response.data[0],
          });
        });

      if (layer_type === "POLYGON") {
        axios({
          method: "get",
          url: `${BASE_URL_BACKEND}/polygon-json/${layer_id}/`,
        }).then((response) => {
          const geojson = response.data;
          addVectorLayertoMap(
            geojson,
            event.target.value,
            props.map,
            PolygonStyleFunction
          );
        });

        // Adding the geojson to the map
      } else if (layer_type === "POINT") {
        axios({
          method: "get",
          url: `${BASE_URL_BACKEND}/point-json/${layer_id}/`,
        }).then((response) => {
          const geojson = response.data;
          addVectorLayertoMap(
            geojson,
            event.target.value,
            props.map,
            PointStyleFunction
          );
        });
      } else if (layer_type === "LINE") {
        axios({
          method: "get",
          url: `${BASE_URL_BACKEND}/line-json/${layer_id}/`,
        }).then((response) => {
          const geojson = response.data;
          addVectorLayertoMap(
            geojson,
            event.target.value,
            props.map,
            LineStyleFunction
          );
        });
      } else if (layer_type === "RASTER") {
        axios({
          method: "get",
          url: `${BASE_URL}/geoserver/rest/workspaces/${category_name}/coveragestores/${layer_name}`,
          auth: {
            username: "admin",
            password: "geoserver",
          },
        }).then((response) => {
          const layer_name_geoserver = response.data.coverageStore.name;
          addRastertoMap(
            category_name,
            layer_name_geoserver,
            event.target.value,
            props.map,
            layer_name,
            geojson_province
          );
        });
      } else {
        console.log("The layer type is none");
      }
    } else {
      const event_value_array = event.target.value.split(",");
      const layer_id = event_value_array[0];
      props.removeLegend(layer_id);
      props.map.getLayers().forEach((layer) => {
        if (layer.get("name") === event.target.value) {
          props.map.removeLayer(layer);
          return null;
        } else {
          return null;
        }
      });
    }
  };

  return (
    <>
      <AccordionDetails>
        <FormGroup>
          <>
            {layers
              ? layers.map((item) => {
                  return (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox
                          value={[item.id, item.g_type, item.name]}
                          onChange={handleChangeCheckbox}
                        />
                      }
                      label={item.name}
                    />
                  );
                })
              : null}
          </>
        </FormGroup>
      </AccordionDetails>
    </>
  );
}
