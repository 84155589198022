/* eslint-disable no-unused-vars */
import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import indexRoutes from "./routes";

function App() {
  return (
    <Router>
      <Routes>
        {indexRoutes.map((route, index) => {
          return (
            <>
              <Route
                key={index}
                exact
                path={route.path}
                element={<route.component />}
              />
              ;
            </>
          );
        })}
      </Routes>
    </Router>
  );
}
export default App;
