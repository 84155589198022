import { Stroke, Style, Fill, Text } from "ol/style";

export function LineStyleFunction(feature, resolution) {
  const style_json = feature.getProperties().style;
  if (!style_json.label_column) {
    return new Style({
      stroke: new Stroke({
        color: `${style_json.stroke_color}`,
        width: `${style_json.stroke_width}`,
      }),
      fill: new Fill({
        color: `${style_json.fill_color}`,
      }),
    });
  } else if (style_json) {
    return new Style({
      stroke: new Stroke({
        color: `${style_json.stroke_color}`,
        width: `${style_json.stroke_width}`,
      }),
      fill: new Fill({
        color: `${style_json.fill_color}`,
      }),
      text: new Text({
        font: `${style_json.label_font_size}px ${style_json.label_font_class}`,
        text: String(feature.getProperties()[`${style_json.label_column}`]),
        placement: "linestring",
        fill: new Fill({
          color: `${style_json.label_fill_color}`,
        }),
        stroke: new Stroke({
          color: `${style_json.label_stroke_color}`,
          width: `${style_json.label_stroke_width}`,
        }),
      }),
    });
  }
  return new Style({
    stroke: new Stroke({
      color: `blue`,
      width: `1`,
    }),
    fill: new Fill({
      color: `red`,
    }),
  });
}
